import { SET_FORMDATA, UPDATE_STEP, GET_COUNTRIES, GET_STATES, RESET_FORMDATA, SET_POINTS,
DATA_ERROR, DATA_SUCCESS, SET_LOADING, RESET_SUCCESS_ERROR } from './types';

export default (state, action) => {
  switch(action.type) {
    case SET_FORMDATA:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.payload
        }
      }
    case RESET_FORMDATA:
      return {
        ...state,
        form: action.payload
      }
    case UPDATE_STEP:
      return {
        ...state,
        steps: {
          ...state.steps,
          [action.payload.step]: action.payload.value
        }
      }
    case GET_COUNTRIES:
      return {
        ...state,
        countries: action.payload
      }
    case GET_STATES:
      return {
        ...state,
        states: action.payload
      }
    case SET_POINTS:
      return {
        ...state,
        points: {
          ...state.points,
          ...action.payload
        }
      }
    case SET_LOADING:
      return {
        ...state,
        loading: true
      }
    case DATA_SUCCESS:
      return {
        ...state,
        success: action.payload.message,
        error: null,
        loading: false
      }
    case DATA_ERROR:
      return {
        ...state,
        error: action.payload,
        success: null,
        loading: false
      }
    case RESET_SUCCESS_ERROR:
      return {
        ...state,
        error: null,
        success: null,
        loading: false
      }
    default:
      return state;
  }
}