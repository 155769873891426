import React from "react";
import ReactGA from "react-ga";

import Button from "../UI/Button";
import CustomSelect from "../UI/CustomSelect";
import Input from "../UI/Input";
import useForm from "../../hooks/useForm";
import validateFields from "../../utils/validateFields";

const ScoreStepThree = (props) => {
  const { setData, data } = props;
  const formData = {
    revenueModel: {
      value: data.revenueModel.value,
      label: data.revenueModel.label,
    },
    traction: {
      value: data.traction.value,
      label: data.traction.label,
    },
    competitors: {
      value: data.competitors,
    },
  };

  const {
    values,
    errors,
    setErrors,
    changeHandler,
    customSelectChangeHandler,
  } = useForm(formData, validateFields);

  const setStepData = () => {
    setData({
      revenueModel: {
        value: values.revenueModel.value,
        label: values.revenueModel.label,
      },
      traction: {
        value: values.traction.value,
        label: values.traction.label,
      },
      competitors: values.competitors.value,
    });
  };

  const submit = (e) => {
    e.preventDefault();

    const newErrors = validateFields(values, true);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      // Calculate points for this step
      const points = {
        revenueModel: 0,
        traction: 0
      }

      const revenueModelVal = values.revenueModel.value;
      const tractionVal = values.traction.value;

      if( revenueModelVal === 'b2b' ) {
        points.revenueModel = 5;
      }else if ( revenueModelVal === 'b2c' ) {
        points.revenueModel = 10;
      }

      if( tractionVal === 'preproduct_community_traction' ) {
        points.traction = 1;
      }else if( tractionVal === 'low_traction' ) {
        points.traction = 3;
      }else if( tractionVal === 'medium_traction' ) {
        points.traction = 5;
      }else if( tractionVal === 'high_medium_traction' ) {
        points.traction = 8;
      }else if( tractionVal === 'high_traction' ) {
        points.traction = 10;
      }

      setStepData();
      props.setPoints(points);
      props.onStepChange(5);
      ReactGA.event({
        category: 'User',
        action: 'Quiz Step 3'
      });
    }
  };

  const goBack = () => {
    setStepData();
    props.onStepChange(3);
  };

  return (
    <div className="c-score-step">
      <div className="c-score-step__head">
        <h4>Where are you so far?</h4>
      </div>
      <div className="c-score-step__body">
        <form className="form">
          <div className="form__body">
            <CustomSelect
              label="Revenue Model"
              name="revenueModel"
              options={[
                { label: "Choose model", value: "" },
                { label: "B2B", value: "b2b" },
                { label: "B2C", value: "b2c" },
                { label: "Other", value: "other" },
              ]}
              value={values.revenueModel.value}
              optionLabel={values.revenueModel.label}
              onChange={customSelectChangeHandler}
              error={errors.revenueModel}
              defaultText="Choose model"
              removeSearch
            />
            <CustomSelect
              label="Traction"
              name="traction"
              options={[
                {
                  label:
                    "How many people have tried or are using your product?",
                  value: "",
                },
                {
                  label: "No one. We don't have a product.",
                  value: "preproduct_no_traction",
                },
                {
                  label:
                    "No one. But we have social media, signups, or other community members.",
                  value: "preproduct_community_traction",
                },
                {
                  label: "We have a few customers or users (5 to 50).",
                  value: "low_traction",
                },
                {
                  label: "We have some customers or users (50 to 500).",
                  value: "medium_traction",
                },
                {
                  label: "We have many customers or users (500 to 5000).",
                  value: "high_medium_traction",
                },
                {
                  label:
                    "We have a lot of customers or users (5000 or more or B2B equivalent).",
                  value: "high_traction",
                },
              ]}
              value={values.traction.value}
              optionLabel={values.traction.label}
              onChange={customSelectChangeHandler}
              error={errors.traction}
              defaultText="How many people have tried or are using your product?"
              removeSearch
            />
            <Input
              label="Who are your competitors?"
              placeholder="Name of Companies"
              name="competitors"
              value={values.competitors.value}
              onChange={changeHandler}
              error={errors.competitors}
            />
          </div>
          <div className="form__foot">
            <Button text="BACK" medium2 light onClick={goBack} />
            <Button text="NEXT" medium2 onClick={submit} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ScoreStepThree;
