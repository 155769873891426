import React, { useEffect } from "react";
import jsonp from "jsonp";

// import Button from "../UI/Button";
import Gauge2 from "../UI/Gauge2";
import Loader from "../UI/Loader";

// Production Mailchimp:
// const url =
  // "https://fi-network.us4.list-manage.com/subscribe/post?u=e5579e82e5c739d30a2279b59&amp;id=8ce247b074";
// Testing
const url = 'https://int3c.us8.list-manage.com/subscribe/post?u=6c5061190a5fb3be4bbae7b1c&amp;id=77c4068bb1';

const ScoreEnd = (props) => {
  const { points, data, onSendData, onResetSuccessAndError } = props;
  let score = 0;

  for (let key in points) {
    score += points[key];
  }

  // Send data to backend
  const sendDataHandler = () => {
    const {
      targetSector,
      valueProposition,
      email,
      teamLocation,
      administrativeAreas,
      averageExperience,
      prevExists,
      prevFundraisingExp,
      revenueModel,
      traction,
      competitors,
      revenue,
      revenuePerMonth,
      growthPerMonth,
      profitability,
      growing,
      yearOneProjectedRevenue,
      yearTwoProjectedRevenue,
      advantages,
      firstName,
      lastName,
    } = data;

    const dataToSend = {
      firstname: firstName,
      lastname: lastName,
      email,
      industry: targetSector.value,
      value_proposition: valueProposition,
      country: teamLocation.value,
      state: administrativeAreas.value,
      team_mem_avg_exp: averageExperience.value,
      prev_ipos_founder: prevExists,
      prev_fund_exp_founders: prevFundraisingExp,
      startup_revenue_model: revenueModel.value,
      traction: traction.value,
      competitors,
      made_revenue: revenue,
      rev_per_month: revenuePerMonth,
      growth_per_month: growthPerMonth,
      profitability,
      see_yourself_growing: growing,
      startup_revenue_y1: yearOneProjectedRevenue,
      startup_revenue_y2: yearTwoProjectedRevenue,
      competitive_adv_top3: advantages,
    };

    onSendData(dataToSend);
  };

  const subscribeToMailChimp = () => {
    const path = `
      ${url}&EMAIL=${data.email}&FNAME=${data.firstName}&LNAME=${data.lastName}&QUIZ=quiz_signup
      &INDUSTRY=${data.targetSector.value}&VALUEPROP=${data.valueProposition}&TEAMCNTRY=${data.teamLocation.value}
      &TEAMPROVIN=${data.administrativeAreas.value}&TEAMEXP=${data.averageExperience.value}&TEAMEXITS=${data.prevExists}
      &TEAMFUNDNG=${data.prevFundraisingExp}&REVENUEMOD=${data.revenueModel.value}&TRACTION=${data.traction.value}
      &COMPETITOR=${data.competitors}&REVPERMON=${data.revenuePerMonth}&GROWPERMON=${data.growthPerMonth}
      &EBITA=${data.profitability}&YEAR1REVEN=${data.yearOneProjectedRevenue}&YEAR2REVEN=${data.yearTwoProjectedRevenue}
      &COMPADVANT=${data.advantages}&LITESCORE=${score}
    `;
    const urlToSend = path.replace("/post?", "/post-json?");
    jsonp(urlToSend, { param: "c" }, (err, data) => {});
  };

  // Subscribe to MC and create user when component is mounted & reset success and error when component is unmounted
  useEffect(() => {
    subscribeToMailChimp();
    sendDataHandler();

    return () => {
      onResetSuccessAndError();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="c-score-end">
      <div className="c-score-end__head">
        <h2>Your Initial Funding Readiness Score:</h2>
      </div>
      <div className="c-score-end__body">
        <div className="gauge-wrap">
          <div className="gauge2">
            <Gauge2 value={score} />
          </div>
          <p>Funding Readiness &#9432;</p>
        </div>
        <div className="c-score-end__body_info">
          <p>
            Want to learn how to improve your score? Check your inbox to
            discover our ecosystem of investors and entrepreneurs today!
          </p>
          <img src={props.icons.funding} alt="Aipril" />
        </div>
      </div>
      <div className="c-score-end__foot">
        <div className="u-btn-wrapper">
          {props.loading && <Loader />}
          {props.error && <p className="u-error-msg">{props.error}</p>}
          {props.success && <p className="u-success-msg">{props.success}</p>}
        </div>
      </div>

      {/* <div className="c-score-end__foot">
        <div className="u-btn-wrapper">
          {props.loading 
            ? <Loader />
            : (!props.error && !props.success) && <Button text="CREATE ACCOUNT" medium2 onClick={sendDataHandler} />
          }
          {props.error && <p className="u-error-msg">{props.error}</p>}
          {props.success && <p className="u-success-msg">{props.success}</p>}
        </div>
        <div className="c-score-end__foot_social">
          <a href="#/"><img src={props.icons.email} alt="Email"/></a>
          <a href="#/"><img src={props.icons.facebook} alt="Facebook"/></a>
          <a href="#/"><img src={props.icons.twitter} alt="Twitter"/></a>
        </div>
      </div>
        */}
    </div>
  );
};

export default ScoreEnd;
