import React, { useReducer } from 'react';
import axios from 'axios';

import ScoreContext from './scoreContext';
import scoreReducer from './scoreReducer';
import { 
  SET_FORMDATA, UPDATE_STEP, GET_COUNTRIES, 
  GET_STATES, RESET_FORMDATA, SET_POINTS, DATA_ERROR, 
  DATA_SUCCESS, SET_LOADING, RESET_SUCCESS_ERROR 
} from './types';
import countriesCSV from '../assets/data/countries.csv';
import statesCSV from '../assets/data/administrative_areas.csv';

const ScoreState = (props) => {
  const initialState = {
    steps: {
      one: false,
      two: false,
      three: false,
      four: false,
      five: false
    },
    form: {
      firstName: '',
      lastName: '',
      targetSector: {
        label: '',
        value: ''
      },
      valueProposition: '',
      email: '',
      teamLocation: {
        label: '',
        value: ''
      },
      administrativeAreas: {
        label: '',
        value: ''
      },
      averageExperience: {
        label: '',
        value: ''
      },
      prevExists: false,
      prevFundraisingExp: false,
      revenueModel: {
        label: '',
        value: ''
      },
      traction: {
        label: '',
        value: ''
      },
      competitors: '',
      revenue: false,
      revenuePerMonth: '',
      growthPerMonth: '',
      profitability: '',
      growing: false,
      yearOneProjectedRevenue: '',
      yearTwoProjectedRevenue: '',
      advantages: ''
    },
    countries: [],
    states: [],
    points: {
      teamLocation: 0,
      averageExperience: 0,
      prevExists: 0,
      prevFundraisingExp: 0,
      revenueModel: 0,
      traction: 0,
      revenuePerMonth: 0,
      growthPerMonth: 0,
      profitability: 0
    },
    error: null,
    success: null,
    loading: false
  };

  const [state, dispatch] = useReducer(scoreReducer, initialState);

  // Set form data
  const setFormData = (data) => {
    saveDataToLocalStorage(data);
    dispatch({
      type: SET_FORMDATA,
      payload: data
    });
  }

  // Send data
  const sendData = async (data) => {
    try { 
      dispatch({ type: SET_LOADING });
      const response = await axios.post(`http://35.245.179.117:8000/api_startups/signup-startup-lite`, data);
      
      dispatch({
        type: DATA_SUCCESS,
        payload: response.data
      }); 
      // Remove data from local storage if user successfully created
      localStorage.removeItem('fin_formdata');
    } catch (err) {
      dispatch({
        type: DATA_ERROR,
        payload: err.response.data.message ? err.response.data.message : 'Something went wrong. Please try again!'
      });
    }
  }

  // Reset success and error
  const resetSuccessAndError = () => {
    dispatch({ type: RESET_SUCCESS_ERROR });
  }

  // Reset form data
  const resetFormData = () => {
    dispatch({
      type: RESET_FORMDATA,
      payload: initialState.form
    });
  }

  // Update steps - if step is visited set to true
  const updateSteps = (data) => {
    dispatch({
      type: UPDATE_STEP,
      payload: data
    });
  }

  // Get all countries
  const getCountries = async () => {
    try {
      const response = await axios.get(countriesCSV);
      
      dispatch({
        type: GET_COUNTRIES,
        payload: response.data.split('\n')
      });
    } catch (err) {
      console.log(err);
    }
  }

  // Get all states
  const getStates = async () => {
    try {
      const response = await axios.get(statesCSV);
      
      dispatch({
        type: GET_STATES,
        payload: response.data.split('\n')
      });
    } catch (err) {
      console.log(err);
    }
  }

  // Set points
  const setPoints = (data) => {
    dispatch({
      type: SET_POINTS,
      payload: data
    });
  }

  const saveDataToLocalStorage = data => {
    let formData;
    if(localStorage.getItem('fin_formdata') !== null) {
      formData = JSON.parse(localStorage.getItem('fin_formdata'));
    }else {
      formData = {};
    }

    const dataToSave = {
      ...formData,
      ...data
    }

    localStorage.setItem('fin_formdata', JSON.stringify(dataToSave));
  }

  const getDataFromLocalStorage = () => {
    if(localStorage.getItem('fin_formdata') !== null) {
      const data = JSON.parse(localStorage.getItem('fin_formdata'));
      dispatch({
        type: SET_FORMDATA,
        payload: data
      });
    }
  }

  return(
    <ScoreContext.Provider
      value={{
        formData: state.form,
        steps: state.steps,
        countries: state.countries,
        states: state.states,
        points: state.points,
        error: state.error,
        success: state.success,
        loading: state.loading,
        setFormData,
        updateSteps,
        resetFormData,
        getCountries,
        getStates,
        setPoints,
        sendData,
        resetSuccessAndError,
        getDataFromLocalStorage
      }}
    >
      {props.children}
    </ScoreContext.Provider>
  );
}

export default ScoreState;