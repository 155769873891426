import React, { useEffect } from "react";
import ReactGA from "react-ga";

import Button from "../UI/Button";

const ScoreStart = (props) => {
  useEffect(() => {
    props.getData();
    // eslint-disable-next-line
  }, []);

  const submit = () => {
    ReactGA.event({
      category: 'User',
      action: 'Quiz Started'
    });
    props.onStepChange(2)
  }

  return (
    <div className="c-score-start">
      <div className="c-score-start__head">
        <img src={props.icon} alt="Get your funding readiness score!" />
        <h2>Get your funding readiness score!</h2>
      </div>
      <div className="c-score-start__body">
        <p>
          Using trend analysis and key industry benchmarks, we give you free
          AI-powered insights into where you stand as a company seeking funding.
        </p>
      </div>
      <div className="c-score-start__foot">
        <Button
          text="LET’S GO!"
          medium2
          onClick={submit}
        />
      </div>
    </div>
  );
};

export default ScoreStart;
