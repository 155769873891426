import React from "react";

import footerLogo from "../../assets/img/footer-logo.svg";
import SocialIcons from "../UI/SocialIcons";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__top">
          <div className="footer__top_box">
            <img src={footerLogo} alt="" />
          </div>
          <div className="footer__top_box">
            <ul>
              <li>
                <a href="http://blog.fi-network.com/?page_id=3749">About Us</a>
              </li>
            </ul>
          </div>
          <div className="footer__top_box">
            <ul>
              <li>
                <a href="/#">Startup Funding Readiness Quiz</a>
              </li>
            </ul>
          </div>
          <div className="footer__top_box"></div>
          <div className="footer__top_box">
            <ul>
              <li>
                <a href="http://blog.fi-network.com/">Blog</a>
              </li>
            </ul>
          </div>
        </div>

        <div className="footer__middle">
          <SocialIcons footer />
        </div>

        <div className="footer__bottom">
          <div className="footer__bottom_box">
            <p>
              &copy; 2020 Funding Innovation Network, LLC. All Rights Reserved.
            </p>
          </div>
          <div className="footer__bottom_box">
            <ul>
              <li>
                <a href="http://blog.fi-network.com/?page_id=3742">
                  Terms of Use
                </a>
              </li>
              <li>
                <a href="http://blog.fi-network.com/?page_id=3573">
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
