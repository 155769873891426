import React from "react";

import Button from "../UI/Button";
import SocialIcons from "../UI/SocialIcons";
import logoImg from "../../assets/img/fin-logo.svg";

const Header = () => {
  return (
    <header className="header">
      <div className="container">
        <div className="l-flex l-flex--space-between">
          <a href="/" className="header__logo">
            <img src={logoImg} alt="Fi Network"/>
          </a>
          <div className="header__right">
            <SocialIcons />
            <Button
              href="http://blog.fi-network.com/"
              text="Blog"
              normal
              link
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
