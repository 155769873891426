import React from "react";
import ReactGA from "react-ga";

import Button from "../UI/Button";
import Input from "../UI/Input";
import Checkbox from "../UI/Checkbox";
import useForm from "../../hooks/useForm";
import validateFields from "../../utils/validateFields";

const ScoreStepFive = (props) => {
  const { setData, data } = props;
  const formData = {
    growing: {
      value: data.growing,
      checkbox: true,
    },
    yearOneProjectedRevenue: {
      required: true,
      value: data.yearOneProjectedRevenue,
    },
    yearTwoProjectedRevenue: {
      required: true,
      value: data.yearTwoProjectedRevenue,
    },
    advantages: {
      required: true,
      value: data.advantages,
    },
  };
  const { values, errors, setErrors, changeHandler, setValues } = useForm(
    formData,
    validateFields
  );

  const setStepData = () => {
    setData({
      growing: values.growing.value,
      yearOneProjectedRevenue: values.yearOneProjectedRevenue.value,
      yearTwoProjectedRevenue: values.yearTwoProjectedRevenue.value,
      advantages: values.advantages.value,
    });
  };

  const submit = (e) => {
    e.preventDefault();

    let newValues = {};
    if (values.growing.value) {
      newValues = {
        ...values,
        yearOneProjectedRevenue: {
          ...values.yearOneProjectedRevenue,
          required: false,
        },
        yearTwoProjectedRevenue: {
          ...values.yearTwoProjectedRevenue,
          required: false,
        },
        advantages: {
          ...values.advantages,
          required: false,
        },
      };
    }

    let newErrors;
    if (Object.keys(newValues).length > 0) {
      newErrors = validateFields(newValues, true);
    } else {
      newErrors = validateFields(values, true);
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setStepData();
      props.onStepChange(7);
      ReactGA.event({
        category: "User",
        action: "Quiz Step 5",
      });
    }
  };

  const changeCheckbox = (e) => {
    let newValues;
    if (e.target.checked) {
      newValues = {
        growing: {
          ...values.growing,
          value: true,
        },
        yearOneProjectedRevenue: {
          ...values.yearOneProjectedRevenue,
          required: false,
        },
        yearTwoProjectedRevenue: {
          ...values.yearTwoProjectedRevenue,
          required: false,
        },
        advantages: {
          ...values.advantages,
          required: false,
        },
      };
      setErrors({});
    } else {
      newValues = {
        growing: {
          ...values.growing,
          value: false,
        },
        yearOneProjectedRevenue: {
          ...values.yearOneProjectedRevenue,
          required: true,
          touched: false,
        },
        yearTwoProjectedRevenue: {
          ...values.yearTwoProjectedRevenue,
          required: true,
          touched: false,
        },
        advantages: {
          ...values.advantages,
          required: true,
          touched: false,
        },
      };
    }
    setValues(newValues);
  };

  const goBack = () => {
    setStepData();
    props.onStepChange(5);
  };

  return (
    <div className="c-score-step">
      <div className="c-score-step__head">
        <h4>Where do You See Yourself Growing?</h4>
      </div>
      <div className="c-score-step__body">
        <form className="form">
          <div className="form__body">
            <Checkbox
              label="I don't know."
              name="growing"
              value={values.growing.value}
              onChange={changeCheckbox}
              error={errors.growing}
            />
            <div className="input-group-wrap">
              <Input
                label="Year 1 projected gross revenue(%)"
                required={!values.growing.value}
                placeholder="Year 1 projected gross revenue"
                name="yearOneProjectedRevenue"
                value={values.yearOneProjectedRevenue.value}
                onChange={changeHandler}
                error={errors.yearOneProjectedRevenue}
              />
              <Input
                label="Year 2 projected gross revenue(%)"
                required={!values.growing.value}
                placeholder="Year 2 projected gross revenue"
                name="yearTwoProjectedRevenue"
                value={values.yearTwoProjectedRevenue.value}
                onChange={changeHandler}
                error={errors.yearTwoProjectedRevenue}
              />
            </div>
            <Input
              label="Top 3 competitive advantages"
              required={!values.growing.value}
              placeholder="Comma separated list"
              name="advantages"
              value={values.advantages.value}
              onChange={changeHandler}
              error={errors.advantages}
            />
          </div>
          <div className="form__foot">
            <Button text="BACK" medium2 light onClick={goBack} />
            <Button text="NEXT" medium2 onClick={submit} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ScoreStepFive;
