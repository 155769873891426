import React from 'react';

const Switch = (props) => {
  return(
    <div className="input-group input-group--switch">
      <label className="input-group__label">{props.label}</label>
      <label>
        <input type="checkbox" name={props.name} value={props.value} onChange={props.onChange} checked={props.value} />
        <div className="input-group__switch"></div>
      </label>
      {props.error && <span className="input-group__error">{props.error}</span>}
    </div>
  );
} 

export default Switch;