import React from "react";

const ImageBox = (props) => {
  let type;
  if (props.type === "secondary") {
    type = "c-image-box--secondary";
  } else if (props.type === "tertiary") {
    type = "c-image-box--tertiary";
  } else {
    type = "c-image-box--primary";
  }

  let imgClass = "";
  if (props.fullImg) {
    imgClass = "full";
  }

  if (props.text) {
    return (
      <div className={`c-image-box ${type}`}>
        <p>{props.text}</p>
        <div className="c-image-box__img-container">
          <img src={props.image} alt={props.text} className={imgClass} />
        </div>
      </div>
    );
  } else {
    return (
      <div className={`c-image-box ${type}`}>
        <div className="c-image-box__img-container">
          <img src={props.image} alt={props.text} className={imgClass} />
        </div>
      </div>
    );
  }
};

export default ImageBox;
