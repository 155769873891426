import React from "react";
import ReactGA from "react-ga";

import Button from "../UI/Button";
import CustomSelect from "../UI/CustomSelect";
import Input from "../UI/Input";
import useForm from "../../hooks/useForm";
import validateFields from "../../utils/validateFields";

const ScoreStepOne = (props) => {
  const { setData, data } = props;
  const formData = {
    firstName: {
      value: data.firstName,
      required: true,
    },
    lastName: {
      value: data.lastName,
      required: true,
    },
    targetSector: {
      value: data.targetSector.value,
      label: data.targetSector.label,
    },
    valueProposition: {
      required: true,
      value: data.valueProposition,
      requiredMessage: "Please share your value proposition or one-liner",
    },
    email: {
      email: true,
      value: data.email,
      emailMessage: "Email address is not valid",
    },
  };

  const {
    values,
    errors,
    setErrors,
    changeHandler,
    customSelectChangeHandler,
  } = useForm(formData, validateFields);

  const setStepData = () => {
    setData({
      firstName: values.firstName.value,
      lastName: values.lastName.value,
      targetSector: {
        value: values.targetSector.value,
        label: values.targetSector.label,
      },
      valueProposition: values.valueProposition.value,
      email: values.email.value,
    });
  };

  const submit = (e) => {
    e.preventDefault();

    const newErrors = validateFields(values, true);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setStepData();
      props.onStepChange(3);
      ReactGA.event({
        category: "User",
        action: "Quiz Step 1",
      });
    }
  };

  const goBack = () => {
    setStepData();
    props.onStepChange(1);
  };

  return (
    <div className="c-score-step">
      <div className="c-score-step__head">
        <h4>Let’s get Started!</h4>
      </div>
      <div className="c-score-step__body">
        <form className="form">
          <div className="form__body">
            <div className="form__group">
              <Input
                label="First Name"
                placeholder="Enter your first name"
                name="firstName"
                value={values.firstName.value}
                onChange={changeHandler}
                error={errors.firstName}
              />
              <Input
                label="Last Name"
                placeholder="Enter your last name"
                name="lastName"
                value={values.lastName.value}
                onChange={changeHandler}
                error={errors.lastName}
              />
            </div>
            <CustomSelect
              label="Target Industry Sector"
              name="targetSector"
              options={[
                { label: "Select a sector", value: "" },
                { label: "AI / ML", value: "aiml" },
                { label: "Biotechnology", value: "biotech" },
                { label: "FinTech", value: "fintech" },
                { label: "Software", value: "software" },
                { label: "Healthcare and MedTech", value: "healthcare" },
                { label: "Education and EdTech", value: "education" },
                { label: "Information Technology (IT)", value: "it" },
                { label: "E-Commerce", value: "ecommerce" },
                { label: "Hardware and IOT", value: "hardware" },
                { label: "Legal", value: "legal" },
                { label: "Manufacturing", value: "manufacturing" },
                { label: "Advertising and AdTech", value: "adverting" },
                { label: "Construction", value: "construction" },
                { label: "Wholesale and Trade", value: "wholesale" },
                { label: "Retail", value: "retail" },
                {
                  label: "Transportation and Warehousing",
                  value: "transportation",
                },
                { label: "Real Estate and PropTech", value: "realestate" },
                {
                  label: "Arts, Entertainment, Sports, and Recreation",
                  value: "entertainment",
                },
                { label: "Hospitality", value: "hospitality" },
                { label: "Public Administration", value: "publicadmin" },
                { label: "Other", value: "other" },
              ]}
              value={values.targetSector.value}
              optionLabel={values.targetSector.label}
              onChange={customSelectChangeHandler}
              error={errors.targetSector}
              defaultText="Select a sector"
            />
            <Input
              label="Value Proposition"
              placeholder="Write one sentence about your company offering."
              name="valueProposition"
              value={values.valueProposition.value}
              onChange={changeHandler}
              error={errors.valueProposition}
            />
            <Input
              label="Contact Info to Send Report"
              placeholder="Email address"
              name="email"
              value={values.email.value}
              onChange={changeHandler}
              error={errors.email}
            />
          </div>
          <div className="form__foot">
            <Button text="BACK" medium2 light onClick={goBack} />
            <Button text="NEXT" type="submit" medium2 onClick={submit} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ScoreStepOne;
