import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";

import Header from "./layout/Header";
import Intro from "./layout/Intro";
import Insights from "./layout/Insights";
import Steps from "./layout/Steps";
import Newsletter from "./layout/Newsletter";
import Footer from "./layout/Footer";
import FundingScore from "./FundingScore";
import ScoreState from "../context/scoreState";

const App = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Google Analytics
    ReactGA.initialize("UA-164788052-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const toggleModal = (value) => {
    setShowModal(value);
    document.querySelector("body").classList.toggle("modal-opened");
  };
  //<Intro onBtnClick={toggleModal} />
  return (
    <ScoreState>
      <Header />
      <Intro onBtnClick={toggleModal} />
      <Insights />
      <Steps />
      <Newsletter />
      <Footer />
      <FundingScore modal={showModal} onModalClose={toggleModal} />
    </ScoreState>
  );
};

export default App;
