import React from 'react';

import gaugeIcon from '../../assets/img/gauge.svg';
import needleIcon from '../../assets/img/needle.svg';

const Gauge2 = (props) => {
  const value = props.value;
  const maxPoints = 90;

  const rotate = ((value/maxPoints) * 270) - 45;

  return(
    <div className="gauge2">
      <img src={gaugeIcon} alt="" className="gauge"/>
      <img src={needleIcon} alt="" className="needle" style={{transform: `rotate(${rotate}deg)`}} />
      <p>{props.value}</p>
    </div>
  );
}

export default Gauge2;