import React, { Fragment, useState, useContext, useEffect } from 'react';

import Modal from './UI/Modal';
import ScoreStart from './score/ScoreStart';
import ScoreEnd from './score/ScoreEnd';
import ScoreStepOne from './score/ScoreStepOne'; 
import ScoreStepTwo from './score/ScoreStepTwo';
import ScoreStepThree from './score/ScoreStepThree';
import ScoreStepFour from './score/ScoreStepFour';
import ScoreStepFive from './score/ScoreStepFive';

import ScoreContext from '../context/scoreContext';

import fundingIcon from '../assets/img/funding-icon.png';
import emailIcon from '../assets/img/email-icon.svg';
import facebookIcon from '../assets/img/facebook-icon.svg';
import twitterIcon from '../assets/img/twitter-icon.svg';

const lastStepIcons = {
  funding: fundingIcon,
  email: emailIcon,
  facebook: facebookIcon,
  twitter: twitterIcon
}

const FundingScore = (props) => {
  const scoreContext = useContext(ScoreContext);
  const formData = scoreContext.formData;
  const steps = scoreContext.steps;
  const setData = scoreContext.setFormData;
  const updateSteps = scoreContext.updateSteps;
  const countries = scoreContext.countries;
  const states = scoreContext.states;
  const setPoints = scoreContext.setPoints;
  const points = scoreContext.points;
  const sendData = scoreContext.sendData;
  const resetSuccessAndError = scoreContext.resetSuccessAndError;
  const loading = scoreContext.loading;
  const error = scoreContext.error;
  const success = scoreContext.success;
  const getDataFromLocalStorage = scoreContext.getDataFromLocalStorage;
  const [step, setStep] = useState(1);
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [statesOptions, setStatesOptions] = useState([]);

  const changeStep = (step) => {
    setStep(step);
  }

  const close = (val) => {
    props.onModalClose(val);
    setStep(1);
    scoreContext.resetFormData();
  }

  useEffect(() => {
    if(countries.length === 0) {
      scoreContext.getCountries();
    }
    if(states.length === 0) {
      scoreContext.getStates();
    }
  }, [scoreContext, countries, states]);

  useEffect(() => {
    if(countries.length > 0) {
      const options = countries.map(country => {
        const lastComma = country.lastIndexOf(',');
        const label = country.substring(0, lastComma);
        const value = country.substring(lastComma + 1);
        const selected = value.trim() === 'US';
        return { label, value, selected };
      });
      setCountriesOptions(options.slice(1, options.length));
    }
    if(states.length > 0) {
      const options = states.map(item => {
        const itemArr = item.split(',');
        const country = itemArr[0].trim();
        const label = itemArr[1].trim();
        const value = itemArr[2].trim();
        return { label, value, country };
      });
      setStatesOptions(options.slice(1, options.length));
    }
  }, [countries, states]);

  return( 
    <Fragment>
      {props.modal &&
        <Modal onClose={close}>
          {step === 1 && <ScoreStart onStepChange={changeStep} icon={fundingIcon} getData={getDataFromLocalStorage} />}
          {step === 2 && <ScoreStepOne 
            onStepChange={changeStep} 
            data={formData} 
            setData={setData} 
            steps={steps} 
            updateSteps={updateSteps} 
          />}
          {step === 3 && <ScoreStepTwo 
            onStepChange={changeStep} 
            data={formData} 
            setData={setData} 
            steps={steps} 
            updateSteps={updateSteps} 
            countries={countriesOptions}
            states={statesOptions}
            setPoints={setPoints}
          />}
          {step === 4 && <ScoreStepThree 
            onStepChange={changeStep} 
            data={formData} 
            setData={setData} 
            steps={steps} 
            updateSteps={updateSteps} 
            setPoints={setPoints}
          />}
          {step === 5 && <ScoreStepFour 
            onStepChange={changeStep} 
            data={formData} 
            setData={setData} 
            steps={steps} 
            updateSteps={updateSteps} 
            setPoints={setPoints}
          />}
          {step === 6 && <ScoreStepFive 
            onStepChange={changeStep} 
            data={formData} 
            setData={setData} 
            steps={steps} 
            updateSteps={updateSteps} 
            setPoints={setPoints}
          />}
          {step === 7 && <ScoreEnd 
            onStepChange={changeStep} 
            icons={lastStepIcons} 
            points={points} 
            data={formData}
            onSendData={sendData}
            onResetSuccessAndError={resetSuccessAndError}
            loading={loading}
            success={success}
            error={error}
          />}
        </Modal>
      }
    </Fragment>
  );
}

export default FundingScore;