import React from 'react';

const SocialIcons = props => {
  return(
    <div className={props.footer ? 'social-icons social-icons--footer' : 'social-icons'}>
      <a href="https://www.facebook.com/FundingInnovationNetwork" target="_blank" rel="noopener noreferrer">
        <i className="fa fa-facebook"></i>
        <span>Facebook</span>
      </a>
      <a href="https://twitter.com/FINetwork4" target="_blank" rel="noopener noreferrer">
        <i className="fa fa-twitter"></i>
        <span>Twitter</span>
      </a>
      <a href="https://www.instagram.com/finetwork4/" target="_blank" rel="noopener noreferrer">
        <i className="fa fa-instagram"></i>
        <span>Instagram</span>
      </a>
      <a href="https://www.linkedin.com/company/fi-network/" target="_blank" rel="noopener noreferrer">
        <i className="fa fa-linkedin"></i>
        <span>Linkedin</span>
      </a>
    </div>
  );
}

export default SocialIcons;