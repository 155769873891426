import React from 'react';

const Checkbox = (props) => {
  return(
    <div className="input-group input-group--checkbox">
      <label className="input-group__label">
        <input type="checkbox" name={props.name} value={props.value} onChange={props.onChange} checked={props.value} />
        <div className="input-group__checkbox"></div>
        <span>{props.label}</span>
      </label>
      {props.error && <span className="input-group__error">{props.error}</span>}
    </div>
  );
}

export default Checkbox;