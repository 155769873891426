import React from 'react';
import ReactSelect from 'react-select';

const Select = ({ onChange, name, ...props }) => {
  const patchedOnChange = (value) => {
    onChange({ currentTarget: { value, name }});
  }
  return <ReactSelect onChange={patchedOnChange} name={name} {...props} />
}

const CustomSelect = (props) => {
  return(
    <div className="input-group">
      <label className="input-group__label">{props.label}</label>
      <Select 
        options={props.options}
        styles={styles.select}
        onChange={props.onChange}
        name={props.name}
        value={{label: props.value === '' ? props.defaultText : props.optionLabel, value: props.value}}
        isSearchable={!props.removeSearch}
        classNamePrefix="custom-select"
      />
      {props.error && <span className="input-group__error">{props.error}</span>}
    </div>
  );
}

const styles = {
  select: {
    
  }
};

export default CustomSelect;