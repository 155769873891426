import React from 'react';

const Input = (props) => {
  return(
    <div className="input-group">
      <label className="input-group__label">{props.label} {props.required && <span>*</span>}</label>
      <input 
        type={props.type ? props.type : 'text'}
        placeholder={props.placeholder} 
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        className={`input-group__input ${props.error ? 'error' : ''}`} 
      />
      {props.error && <span className="input-group__error">{props.error}</span>}
    </div>
  );
}

export default Input;