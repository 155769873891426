import React, { useState } from "react";
import ReactGA from "react-ga";

import Button from "../UI/Button";
import CustomSelect from "../UI/CustomSelect";
import Switch from "../UI/Switch";
import useForm from "../../hooks/useForm";
import validateFields from "../../utils/validateFields";

const ScoreStepTwo = (props) => {
  const [selected] = useState(props.countries.filter(country => country.selected));
  const [statesOptionsUS] = useState(props.states.filter(state => state.country === 'US' ));
  const [statesOptionsCA] = useState(props.states.filter(state => state.country === 'CA' ));
  const { setData, data } = props;
  const formData = {
    teamLocation: {
      value: data.teamLocation.value || selected[0].value,
      label: data.teamLocation.label || selected[0].label
    },
    administrativeAreas: {
      value: data.administrativeAreas.value,
      label: data.administrativeAreas.label
    },
    averageExperience: {
      value: data.averageExperience.value,
      label: data.averageExperience.label
    },
    prevExists: {
      checkbox: true,
      value: data.prevExists,
    },
    prevFundraisingExp: {
      checkbox: true,
      value: data.prevFundraisingExp,
    },
  };
  const {
    values,
    setValues,
    errors,
    setErrors,
    checkboxChangeHandler,
    customSelectChangeHandler
  } = useForm(formData, validateFields);

  const setStepData = () => {
    setData({
      teamLocation: {
        value: values.teamLocation.value,
        label: values.teamLocation.label
      },
      administrativeAreas: {
        value: values.administrativeAreas.value,
        label: values.administrativeAreas.label
      },
      averageExperience: {
        value: values.averageExperience.value,
        label: values.averageExperience.label
      },
      prevExists: values.prevExists.value,
      prevFundraisingExp: values.prevFundraisingExp.value
    });
  }

  const submit = (e) => {
    e.preventDefault();

    const newErrors = validateFields(values, true);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      // Calculate points for this step
      const points = {
        teamLocation: 0,
        averageExperience: 0,
        prevExists: 0,
        prevFundraisingExp: 0
      };
      
      const teamLocationVal = values.teamLocation.value.trim();
      const administrativeAreasVal = values.administrativeAreas.value.trim();
      const averageExperienceVal = values.averageExperience.value;

      if( teamLocationVal === 'CA' ) {
        points.teamLocation = 10;
      }else if( teamLocationVal === 'US' ) {
        if( administrativeAreasVal === 'NY' || administrativeAreasVal === 'MA' ) {
          points.teamLocation = 8;
        }else if( administrativeAreasVal === 'TX' || administrativeAreasVal === 'FL' ) {
          points.teamLocation = 6;
        }else {
          points.teamLocation = 3;
        }
      }else {
        points.teamLocation = 1;
      }

      if( averageExperienceVal === 'team_exp_1' ) {
        points.averageExperience = 3;
      }else if( averageExperienceVal === 'team_exp_2' ) {
        points.averageExperience = 6;
      }else if( averageExperienceVal === 'team_exp_3' ) {
        points.averageExperience = 8;
      }else if( averageExperienceVal === 'team_exp_4' ) {
        points.averageExperience = 10;
      }

      if( values.prevExists.value ) {
        points.prevExists = 10;
      }

      if( values.prevFundraisingExp.value ) {
        points.prevFundraisingExp = 10;
      }

      setStepData();
      props.setPoints(points);
      props.onStepChange(4);
      ReactGA.event({
        category: 'User',
        action: 'Quiz Step 2'
      });
    }
  };

  const goBack = () => {
    setStepData();
    props.onStepChange(2);
  }

  const changeCountries = (e) => {
    let newValues = {
      ...values,
      [e.currentTarget.name]: {
        ...values[e.currentTarget.name],
        value: e.currentTarget.value.value,
        label: e.currentTarget.value.label,
        touched: true
      },
      administrativeAreas: {
        ...values.administrativeAreas,
        value: "",
        label: "Choose State or Province"
      }
    }
    setValues(newValues);
  }

  return (
    <div className="c-score-step">
      <div className="c-score-step__head">
        <h4>Who’s Steering the Ship?</h4>
      </div>
      <div className="c-score-step__body">
        <form className="form">
          <div className="form__body">
            <CustomSelect 
              label="Team Location"
              name="teamLocation"
              options={props.countries}
              onChange={changeCountries}
              error={errors.teamLocation}
              value={values.teamLocation.value}
              optionLabel={values.teamLocation.label}
            />
            {(values.teamLocation.value.trim() === 'US' || values.teamLocation.value.trim() === 'CA') &&
              <CustomSelect 
                label="Administrative Areas"
                name="administrativeAreas"
                options={values.teamLocation.value.trim() === 'US' ? statesOptionsUS : statesOptionsCA}
                onChange={customSelectChangeHandler}
                error={errors.administrativeAreas}
                value={values.administrativeAreas.value}
                optionLabel={values.administrativeAreas.label}
                defaultText="Choose State or Province"
              />
            }
            <CustomSelect
              label="Team Members Average Experience"
              name="averageExperience"
              options={[
                { label: "Select Number", value: "" },
                {
                  label: "Recent graduate or no experience in field",
                  value: "team_exp_0",
                },
                {
                  label: "Less than 5 years of experience in field",
                  value: "team_exp_1",
                },
                {
                  label: "5 to 10 years of experience in field",
                  value: "team_exp_2",
                },
                {
                  label: "Between 10 and 20 years of experience",
                  value: "team_exp_3",
                },
                {
                  label: "More than 20 years of experience",
                  value: "team_exp_4",
                },
              ]}
              value={values.averageExperience.value}
              onChange={customSelectChangeHandler}
              error={errors.averageExperience}
              optionLabel={values.averageExperience.label}
              defaultText="Select number"
              removeSearch
            />
            <Switch
              label="Previous exits/IPOs of Founders"
              name="prevExists"
              value={values.prevExists.value}
              onChange={checkboxChangeHandler}
              error={errors.prevExists}
            />
            <Switch
              label="Previous fundraising experience of founders"
              name="prevFundraisingExp"
              value={values.prevFundraisingExp.value}
              onChange={checkboxChangeHandler}
              error={errors.prevFundraisingExp}
            />
          </div>
          <div className="form__foot">
            <Button text="BACK" medium2 light onClick={goBack} />
            <Button text="NEXT" medium2 onClick={submit} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ScoreStepTwo;
