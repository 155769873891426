import React from "react";

const Button = (props) => {
  let output;
  let classes = "btn";

  if (props.normal) {
    classes += " btn--text";
  }

  if (props.medium) {
    classes += " btn--md";
  }

  if (props.medium2) {
    classes += " btn--md2";
  }

  if (props.medium3) {
    classes += " btn--md3";
  }

  if (props.large) {
    classes += " btn--lg";
  }

  if (props.light) {
    classes += " btn--light";
  }

  if (props.link) {
    output = (
      <a href={props.href} className={classes}>
        {props.text}
      </a>
    );
  } else {
    output = (
      <button
        type={props.type && props.type === "submit" ? "submit" : "button"}
        className={classes}
        onClick={props.onClick}
      >
        {props.text}
      </button>
    );
  }

  return output;
};

export default Button;
