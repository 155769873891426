import React from "react";

import Logo from "../UI/Logo";
import insightsLogo from "../../assets/img/insights-logo.svg";

const Steps = () => {
  return (
    <section className="s-steps">
      <div className="container">
        <h2>How our FIN Score Works</h2>
        <div className="s-steps__bar">
          <div>Input your data</div>
          <div className="s-steps__centered">FIN Proprietary AI analysis</div>
          <div>Your custom insights</div>
        </div>
        <div className="s-steps__content">
          <div className="s-steps__content_box-center">
            <img src={insightsLogo} alt="Insights by Aipril" />
            <Logo fill="#fcd800" />
          </div>
          <div className="s-steps__content_box">
            <div className="s-steps__content_box-left">
              <h3>Investors</h3>
              <p>Match with key startups that fit your funding preferences.</p>
            </div>
            <div className="s-steps__content_box-right">
              <p>
                Deal Pipeline of Matched Startups &amp; BSP | Business Insights
                | Analytical Tools | Digital Management Tools.
              </p>
            </div>
          </div>
          <div className="s-steps__content_box">
            <div className="s-steps__content_box-left">
              <h3 className="color-2">Startups</h3>
              <p>We analyze your profile info, team, market, and more.</p>
            </div>
            <div className="s-steps__content_box-right">
              <p>
                Funding Readiness Score© | FIN Score© | Business Insights |
                Matched Investors &amp; BSPs | Digital Management Tools.
              </p>
            </div>
          </div>
          <div className="s-steps__content_box">
            <div className="s-steps__content_box-left">
              <h3 className="color-3">Resources</h3>
              <p>Connect with startups and share your services!</p>
            </div>
            <div className="s-steps__content_box-right">
              <p>
                Pipeline of Matched Startups &amp; Investors | Business Insights
                | Analytical Tools | Digital Management Tools.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Steps;
