import React from "react";

import ImageBox from "../UI/ImageBox";
import solarSystem from "../../assets/img/solar-system.svg";
import hands from "../../assets/img/hands.svg";
import girl from "../../assets/img/girl.svg";

const Insights = () => {
  return (
    <section className="s-insights">
      <div className="container">
        <div className="s-insights__box">
          <h2>Increase your odds of success with FIN.</h2>
          <p>FIN offers AI-powered insights to drive your business forward.</p>
          <div className="s-insights__box_image-boxes">
            <ImageBox text="Learn Actionable Insights" image={solarSystem} />
            <ImageBox
              text="Match With Investors"
              image={hands}
              type="secondary"
            />
            <ImageBox
              text="Power up With Resources"
              image={girl}
              type="tertiary"
              fullImg
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Insights;
