import React, { useState } from "react";

import Button from "../UI/Button";

import ImageBox from "../UI/ImageBox";
import entrepreneur from "../../assets/img/entrepreneur-icon.svg";
import investor from "../../assets/img/investor-icon.svg";

const Intro = (props) => {
  return (
    <section className="s-intro">
      <div className="container">
        <div className="s-intro__box">
          <div className="s-intro__box__text">
            <h1>The Platform Where Innovation Meets Investment</h1>
            <br />
            <p>See if you’re ready for funding now.</p>
            <div className="u-btn-wrapper">
              <Button
                text="Get your funding readiness score"
                medium2
                onClick={() => props.onBtnClick(true)}
              />
            </div>
          </div>
          <div className="s-intro__box__roles">
            <div className="s-intro__box__roles__subbox s-intro__box__roles__entrepreneur">
              <ImageBox image={entrepreneur} />
              <h4>Join as an Entrepreneur</h4>
              <p>
                Receive insights on your funding readiness and a list of
                tailored investor matches.
              </p>
              <div className="u-btn-wrapper">
                <a href="#NEWSLETTERFORM">
                  <Button text="Sign Up" medium3 />
                </a>
              </div>
            </div>
            <div className="s-intro__box__roles__subbox s-intro__box__roles__investor">
              <ImageBox image={investor} type="secondary" />
              <h4>Join as an Investor</h4>
              <p>
                Receive qualified deal flow based on your preferences. Track
                companies over time.
              </p>
              <div className="u-btn-wrapper">
                <a href="#NEWSLETTERFORM">
                  <Button text="Sign Up" medium3 />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Intro;
