import React from "react";

import Mailchimp from "../mailchimp/Mailchimp";

// Production Mailchimp:
const url =
  "https://fi-network.us4.list-manage.com/subscribe/post?u=e5579e82e5c739d30a2279b59&amp;id=8ce247b074";

const Newsletter = () => {
  return (
    <section className="s-newsletter">
      <a name="NEWSLETTERFORM"></a>
      <div className="container">
        <div className="s-newsletter__box">
          <h2>The FIN startup ecosystem is here!</h2>
          <p>Signup to get Beta Access to the platform.</p>
          <Mailchimp
            action={url}
            fields={[
              {
                name: "EMAIL",
                placeholder: "Email",
                type: "email",
                required: true,
              },
            ]}
            messages={{
              sending: "Sending...",
              success: "Thank you for subscribing!",
              error: "An unexpected internal error has occurred.",
              empty: "Email address is required!",
              duplicate: "Already subscribed!",
              button: "Subscribe",
            }}
            className="form form--newsletter"
          />
        </div>
      </div>
      <div className="s-newsletter__bg"></div>
    </section>
  );
};

export default Newsletter;
