import React from 'react';
import ReactGA from "react-ga";

import Button from '../UI/Button';
import Input from '../UI/Input';
import Checkbox from '../UI/Checkbox';
import useForm from '../../hooks/useForm';
import validateFields from '../../utils/validateFields';


const ScoreStepFour = (props) => {
  const { setData, data } = props;
  const formData = {
    revenue: {
      value: data.revenue,
      checkbox: true
    },
    revenuePerMonth: {
      required: true,
      value: data.revenuePerMonth,
      number: true,
      numberMessage: 'This field accepts only numbers'
    },
    growthPerMonth: {
      required: true,
      value: data.growthPerMonth,
      number: true
    },
    profitability: {
      required: true,
      value: data.profitability,
      number: true
    }
  };
  const { values, errors, setErrors, changeHandler, setValues } = useForm(formData, validateFields);

  const setStepData = () => {
    setData({
      revenue: values.revenue.value,
      revenuePerMonth: values.revenuePerMonth.value,
      growthPerMonth: values.growthPerMonth.value,
      profitability: values.profitability.value
    });
  }

  const submit = (e) => {
    e.preventDefault();

    let newValues = {};
    if(values.revenue.value) {
      newValues = {
        ...values,
        revenuePerMonth: {
          ...values.revenuePerMonth,
          required: false
        },
        growthPerMonth: {
          ...values.growthPerMonth,
          required: false
        },
        profitability: {
          ...values.profitability,
          required: false
        }
      }
    }

    let newErrors;
    if(Object.keys(newValues).length > 0) {
      newErrors = validateFields(newValues, true);
    }else {
      newErrors = validateFields(values, true);
    }

    setErrors(newErrors);

    if(Object.keys(newErrors).length === 0) {
      // Calculate points for this step
      const points = {
        revenuePerMonth: 0,
        growthPerMonth: 0,
        profitability: 0
      }

      const revenuePerMonthVal = parseInt(values.revenuePerMonth.value);
      const growthPerMonthVal = parseInt(values.growthPerMonth.value);
      const profitabilityVal = parseInt(values.profitability.value);

      if( revenuePerMonthVal >= 50000 && revenuePerMonthVal <= 500000 ) {
        points.revenuePerMonth = 3;
      }else if( revenuePerMonthVal > 500000 && revenuePerMonthVal <= 5000000 ) {
        points.revenuePerMonth = 6;
      }else if( revenuePerMonthVal > 5000000 && revenuePerMonthVal <= 20000000 ) {
        points.revenuePerMonth = 8;
      }else if( revenuePerMonthVal > 20000000 ) {
        points.revenuePerMonth = 10;
      }

      if( growthPerMonthVal >= 20 && growthPerMonthVal <= 35 ) {
        points.growthPerMonth = 3;
      }else if( growthPerMonthVal > 35 && growthPerMonthVal <= 50 ) {
        points.growthPerMonth = 6;
      }else if( growthPerMonthVal > 50 && growthPerMonthVal <= 70 ) {
        points.growthPerMonth = 8;
      }else if( growthPerMonthVal > 70 ) {
        points.growthPerMonth = 10;
      }

      if( profitabilityVal >= 6 && profitabilityVal <= 12 ) {
        points.profitability = 3;
      }else if( profitabilityVal > 12 && profitabilityVal <= 18 ) {
        points.profitability = 6;
      }else if( profitabilityVal > 18 && profitabilityVal <= 30 ) {
        points.profitability = 8;
      }else if( profitabilityVal > 30 ) {
        points.profitability = 10;
      }

      setStepData();
      props.setPoints(points);
      props.onStepChange(6);
      ReactGA.event({
        category: 'User',
        action: 'Quiz Step 4'
      });
    }
  }

  const changeCheckbox = (e) => {
    let newValues;
    if(e.target.checked) {
      newValues = {
        revenue: {
          ...values.revenue,
          value: true
        },
        revenuePerMonth: {
          ...values.revenuePerMonth,
          required: false
        },
        growthPerMonth: {
          ...values.growthPerMonth,
          required: false
        },
        profitability: {
          ...values.profitability,
          required: false
        }
      }
      setErrors({});
    }else {
      newValues = {
        revenue: {
          ...values.revenue,
          value: false
        },
        revenuePerMonth: {
          ...values.revenuePerMonth,
          required: true,
          touched: false
        },
        growthPerMonth: {
          ...values.growthPerMonth,
          required: true,
          touched: false
        },
        profitability: {
          ...values.profitability,
          required: true,
          touched: false
        }
      }
    }
    setValues(newValues);
  }

  const goBack = () => {
    setStepData();
    props.onStepChange(4);
  }

  return(
    <div className="c-score-step">
      <div className="c-score-step__head">
        <h4>Have you Made any Revenue?</h4>
      </div>
      <div className="c-score-step__body">
        <form className="form">
          <div className="form__body">
            <Checkbox 
              label="No we have not."
              name="revenue"
              value={values.revenue.value}
              onChange={changeCheckbox}
              error={errors.revenue}
            />
            <div className="input-group-wrap">
              <Input 
                label="Revenue Per Month(USD)"
                required={!values.revenue.value}
                placeholder="Revenue"
                name="revenuePerMonth"
                value={values.revenuePerMonth.value}
                onChange={changeHandler}
                error={errors.revenuePerMonth}
              />
              <Input 
                label="Growth Per Month(%)"
                required={!values.revenue.value}
                placeholder="Growth"
                name="growthPerMonth"
                value={values.growthPerMonth.value}
                onChange={changeHandler}
                error={errors.growthPerMonth}
              />
            </div>
            <Input 
              label="Profitability(EBITA)(USD)"
              required={!values.revenue.value}
              placeholder="Profitability"
              name="profitability"
              value={values.profitability.value}
              onChange={changeHandler}
              error={errors.profitability}
            />
          </div>
          <div className="form__foot">
            <Button text="BACK" medium2 light onClick={goBack} />
            <Button text="NEXT" medium2 onClick={submit} />
          </div>
        </form>
      </div>
    </div>
  );
}

export default ScoreStepFour;