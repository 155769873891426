import isEmpty from 'is-empty';

const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

const validatNumber = (value) => {
  const re = /^\d+$/;
  return re.test(value);
}

export default (fields, submit = false) => {
  let errors = {};  
  for(let field in fields) {
    if(submit) {
      fields[field].touched = true;
    }

    if(fields[field].required && isEmpty(fields[field].value) && fields[field].touched) {
      errors[field] = fields[field].requiredMessage ? fields[field].requiredMessage : 'This field is required';
    }

    if(fields[field].required && fields[field].checkbox && !fields[field].value && fields[field].touched) {
      errors[field] = fields[field].requiredMessage ? fields[field].requiredMessage : 'This field is required';
    }

    if(!errors[field] && fields[field].email && !validateEmail(fields[field].value) && fields[field].touched) {
      errors[field] = fields[field].emailMessage ? fields[field].emailMessage : 'Invalid email address';
    }

    if(!errors[field] && fields[field].number && fields[field].value !== '' && !validatNumber(fields[field].value) && fields[field].touched) {
      errors[field] = fields[field].numberMessage ? fields[field].numberMessage : 'Only numbers allowed';
    }
  }

  return errors;
}