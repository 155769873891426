import React from 'react';

const Logo = ({ fill }) => {
  return (
    <div className="logo">
      <svg xmlns="http://www.w3.org/2000/svg" width="160.651" height="160.661" viewBox="0 0 160.651 160.661">
        <path d="M80.33 160.661a80.33 80.33 0 1 1 80.321-80.33 80.421 80.421 0 0 1-80.321 80.33zM32.5 118.393a61.5 61.5 0 0 0 20.865 16.816 61 61 0 0 0 65.31-7.276 61.727 61.727 0 0 0 9.483-9.54h-11.945a25.575 25.575 0 0 1-23.28-15.065L80.33 75.25l-12.613 28.078a25.575 25.575 0 0 1-23.28 15.065zm47.83-89.936l30.1 67.015a6.354 6.354 0 0 0 5.782 3.737h22.278a61.153 61.153 0 1 0-116.33 0h22.277a6.362 6.362 0 0 0 5.792-3.737l30.1-67.015z" fill={fill} />
      </svg>
    </div>
  );
}

export default Logo;