export const SET_FORMDATA = 'SET_FORMDATA';
export const RESET_FORMDATA = 'RESET_FORMDATA';
export const UPDATE_STEP = 'UPDATE_STEP';

export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_STATES = 'GET_STATES';

export const SET_POINTS = 'SET_POINTS';

export const DATA_SUCCESS = 'DATA_SUCCESS';
export const DATA_ERROR = 'DATA_ERROR';
export const SET_LOADING = 'SET_LOADING';
export const RESET_SUCCESS_ERROR = 'RESET_SUCCESS_ERROR';