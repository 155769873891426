import React, { useEffect, useRef } from 'react';

import closeIcon from '../../assets/img/close-icon.svg';

const Modal = (props) => {
  const modalOverlay = useRef();
  const modalContent = useRef();

  useEffect(() => {
    modalOverlay.current.style.opacity = 1;
    modalContent.current.style.opacity = 1;
    modalContent.current.style.top = 0;
  }, []);

  const close = () => {
    modalOverlay.current.style.opacity = 0;
    modalContent.current.style.opacity = 0;
    modalContent.current.style.top = -100 + 'px';

    setTimeout(() => {
      props.onClose(false);
    }, 400);
  }

  return(
    <div className="c-modal">
      <div className="c-modal__overlay" onClick={close} ref={modalOverlay}></div>
      <div className="c-modal__box" ref={modalContent}>
        <div className="c-modal__box_close" onClick={close}>
          <img src={closeIcon} alt="Close icon"/>
        </div>
        <div className="c-modal__box_content">
          {props.children}
        </div>
      </div>
    </div>
  );
}

export default Modal;